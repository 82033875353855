<template>
    <div class="blogger-my-trips" v-if="translates && translates[langUrl]">
        <div class="blogger-my-trips__header">
            <div
                class="blogger-my-trips__header__title"
                :class="selectedMenu === 'active' ? 'active' : ''"
                @click="setMenu('active')"
            >{{ translates[langUrl].trips_active[lang] }}</div>
            <div
                class="blogger-my-trips__header__title"
                :class="selectedMenu === 'completed' ? 'active' : ''"
                @click="setMenu('completed')"
            >{{ translates[langUrl].trips_completed[lang] }}</div>
            <div
                class="blogger-my-trips__header__title"
                :class="selectedMenu === 'declined' ? 'active' : ''"
                @click="setMenu('declined')"
            >{{ translates[langUrl].trips_declined[lang] }}</div>
            <div
                class="blogger-my-trips__header__title"
                :class="selectedMenu === 'requests' ? 'active' : ''"
                @click="setMenu('requests')"
            >{{ translates[langUrl].trips_requests[lang] }}</div>
        </div>
        
        <div class="blogger-my-trips__content">
            <template v-for="(trip, index1) in filteredBloggerTrips">
                <PreviewCardBlogger
                    :key="`trip-${index1}`"
                    :blogger-trip="trip"
                />
            </template>
            <template v-for="(tripRequest, index2) in filteredTripRequests">
                <TripRequest
                    :key="`tripRequest-${index2}`"
                    :trip-request="tripRequest"
                />
            </template>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import { TRIP_STATUS, TRIP_TYPE, TRIP_LEVEL_TITLE, BLOGGER_TRIP_STATUS, TRIP_REQUEST_STATUS } from '@/vars';

    import BaseButton from '../../components/common/BaseButton.vue';
    import BaseCheckbox from '../../components/common/BaseCheckbox.vue';
    import PreviewCardBlogger from '../../components/common/trip/PreviewCardBlogger.vue';
    import TripRequest from '../../components/common/TripRequest.vue';
    
    export default {
        name: 'blogger-my-trips',
        metaInfo() {
            return {
                title: this.translates[this.langUrl] ? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            BaseButton,
            BaseCheckbox,
            PreviewCardBlogger,
            TripRequest
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('quiz', ['summary']),
            ...mapState('dictionaries', ['types', 'countries', 'cities']),
            ...mapState('bloggerTrips', {
                bloggerTrips: state => state.entities,
                bloggerTripsCount: state => state.entitiesCount,
            }),
            ...mapState('tripRequests', {
                tripRequests: state => state.entities
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            showMoreButton() {
                return this.page * this.itemsPerPage < this.bloggerTripsCount;
            },
            selectLevels() {
                const levels = [];
                for(const key in TRIP_LEVEL_TITLE) {
                    levels.push({ label: TRIP_LEVEL_TITLE[key], value: key });
                }
                return levels;
            },
            selectDirections() {
                return this.countries ? (this.showAll.directions ? this.countries : this.countries.slice(0, this.showAll.itemsCount)) : [];
            },
            selectTypes() {
                return this.types ? (this.showAll.types ? this.types : this.types.slice(0, this.showAll.itemsCount)) : [];
            },
            selectMonths() {
                return [];
            },
            filteredBloggerTrips() {
                switch(this.selectedMenu) {
                    case 'active':
                        return this.bloggerTrips.filter(item => [
                            BLOGGER_TRIP_STATUS.ORGANIZER_MODERATED, 
                            BLOGGER_TRIP_STATUS.BLOGGER_MODERATED, 
                            BLOGGER_TRIP_STATUS.MODERATED, 
                            BLOGGER_TRIP_STATUS.ACTIVE].indexOf(item.status) >= 0);
                    break;
                    case 'completed':
                        return this.bloggerTrips.filter(item => BLOGGER_TRIP_STATUS.COMPLETED === item.status);
                    break;
                    case 'declined':
                        return this.bloggerTrips.filter(item => BLOGGER_TRIP_STATUS.DECLINED === item.status);
                    break;
                }
                return [];
            },
            filteredTripRequests() {
                switch(this.selectedMenu) {
                    case 'requests':
                        return this.tripRequests;
                    break;
                }
                return [];
            }
        },
        data: () => ({
            TRIP_STATUS,
            TRIP_LEVEL_TITLE,
            BLOGGER_TRIP_STATUS,
            isMobile,
            isDesktop: false,
            linkDialog: false,
            showAll: {
                itemsCount: 3,
                directions: false,
                types: false,
                months: false
            },
            page: 1,
            itemsPerPage: 6,
            filter: {
                level: [],
                directions: [],
                tripType: []
            },
            selectedMenu: 'active',
            activeTours: false,
            completedTours: false,
            canceledTours: false,
            langUrl: '/blogger/trips/my'
        }),
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            if(this.$route.params.tab) {
                this.selectedMenu = this.$route.params.tab;
            }
            store.commit('bloggerTrips/CLEAR_ENTITIES');
            await store.dispatch('tripRequests/fetch', { filter: {status: TRIP_REQUEST_STATUS.CREATED } });
            await this.getTrips();
            this.$root.$emit('preloaderHide');
        },
        methods: {
            onResize() {
                this.isDesktop = window.innerWidth >= 768;
            },
            async linkCopy() {
                const quizLink = `${process.env.VUE_APP_CURRENT_URL}/quiz/${this.user.social || this.user._id}`
                navigator.clipboard.writeText(quizLink).then(() => this.linkDialog = true);
            },
            async getTrips() {
                await store.dispatch('bloggerTrips/fetch', { filter: {
                    ...this.filter,
                    type: TRIP_TYPE.TOUR_LEADER,
                    status: TRIP_STATUS.ACTIVE
                }, sorting: this.sorting, page: this.page, itemsPerPage: this.itemsPerPage });
            },
            async applyFilters() {
                this.page = 1;
                await this.getTrips();
            },
            async showMore() {
                this.page += 1;
                await this.getTrips();
            },
            setMenu(menu) {
                this.selectedMenu = menu;
            }
        },
    };
</script>

<style lang="scss">
.blogger-my-trips {
    display: flex;
    flex-direction: column;
    &__header {
        display: flex;
        flex-direction: row;
        margin: 40px auto;
        overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        @media all and (max-width: 768px) {
            width: 100%;
        }
        &__title {
            padding-left: 32px;
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
            color: #A1A4B1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:first-of-type {
                padding-left: 0;
            }
            @media all and (max-width: 768px) {
                font-size: 16px;
                padding-left: 20px;
                &:first-of-type {
                    padding-left: 20px;
                }
                &:last-of-type {
                    padding-right: 20px;
                }
            }
            &.active {
                color: #273155;
                font-size: 28px;
                line-height: 140%;
                @media all and (max-width: 768px) {
                    font-size: 24px;
                }
            }
            
            @media all and (max-width: 768px) {
                position: relative;
                font-weight: 800;
                font-size: 24px;
                line-height: 150%;
                text-align: center;
                letter-spacing: -0.02em;
            }
        }
    }

}
</style>