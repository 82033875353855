<template>
    <div
        class="trip-request"
        :class="{'trip-request-answered': tripRequest.status === TRIP_REQUEST_STATUS.ANSWERED}">
        <div class="trip-request__detail__blogger" v-if="showHeader">
            <div class="trip-request__detail__blogger__title">
                {{ tripRequest.blogger.social }} ({{ tripRequest.blogger.firstname }} {{ tripRequest.blogger.lastname }})
            </div>
            <div class="trip-request__detail__blogger__links" v-if="tripRequest.trip && tripRequest.trip.type === TRIP_TYPE.TOUR_BLOGGER">
                <a @click="viewTrip" class="trip-request__detail__blogger__links__item">{{ translates[langUrl].link__viewTrip[lang] }}</a>
                <a @click="editTrip" class="trip-request__detail__blogger__links__item">{{ translates[langUrl].link__editTrip[lang] }}</a>
            </div>
            <BaseButton
                class="button"
                v-if="showButton && tripRequest.status !== TRIP_REQUEST_STATUS.ANSWERED"
                @click="$emit('requestConfirmation')">{{ translates[langUrl].button_chooseTrip[lang] }}</BaseButton>
        </div>
        <!-- <div class="trip-request__detail__columns" v-if="tripRequest.common">
            <div class="trip-request__detail column">
                <div class="trip-request__detail__key">{{ translates[langUrl].mainDirection[lang] }}</div>
                <div class="trip-request__detail__value">
                    {{ formattedDirections(tripRequest.direction === 'other' ? tripRequest.directionOther : tripRequest.direction) }}
                </div>
            </div>
            <div class="trip-request__detail column" v-if="tripRequest.directionAdditional">
                <div class="trip-request__detail__key">{{ translates[langUrl].directionsAdditional[lang] }}</div>
                <div class="trip-request__detail__value">
                    {{ formattedDirectionsAdditional(tripRequest.directionAdditional === 'other' ? tripRequest.directionAdditionalOther : tripRequest.directionAdditional) }}
                </div>
            </div>
            <div class="trip-request__detail column">
                <div class="trip-request__detail__key">{{ translates[langUrl].comfort[lang] }}</div>
                <div class="trip-request__detail__value">
                    {{ formattedComfort(tripRequest.comfort) }}
                </div>
            </div>
            <div class="trip-request__detail column">
                <div class="trip-request__detail__key">{{ translates[langUrl].start[lang] }}</div>
                <div class="trip-request__detail__value">
                    <span v-if="tripRequest.start">{{ tripRequest.start | moment('DD MMM YYYY') }}</span>
                </div>
            </div>
            <div class="trip-request__detail column" v-if="tripRequest.duration">
                <div class="trip-request__detail__key">{{ translates[langUrl].duration[lang] }}</div>
                <div class="trip-request__detail__value">
                    {{ tripRequest.duration }} {{ lastDigitToWord(tripRequest.duration) }}
                </div>
            </div>
        </div> -->
        <div v-if="tripRequest.common" class="trip-request__detail__rows">
            <div class="trip-request__detail__row">
                <div class="trip-request__detail__key">{{ translates[langUrl].mainDirection[lang] }}:</div>
                <div class="trip-request__detail__value">
                    {{ formattedDirections(tripRequest.direction === 'other' ? tripRequest.directionOther : tripRequest.direction) }}
                </div>
            </div>
            <div class="trip-request__detail__row" v-if="tripRequest.directionAdditional">
                <div class="trip-request__detail__key">{{ translates[langUrl].directionsAdditional[lang] }}:</div>
                <div class="trip-request__detail__value">
                    {{ formattedDirectionsAdditional(tripRequest.directionAdditional === 'other' ? tripRequest.directionAdditionalOther : tripRequest.directionAdditional) }}
                </div>
            </div>
            <div class="trip-request__detail__row">
                <div class="trip-request__detail__key">{{ translates[langUrl].comfort[lang] }}:</div>
                <div class="trip-request__detail__value">
                    {{ formattedComfort(tripRequest.comfort) }}
                </div>
            </div>
            <div class="trip-request__detail__row">
                <div class="trip-request__detail__key">{{ translates[langUrl].start[lang] }}:</div>
                <div class="trip-request__detail__value">
                    <span v-if="tripRequest.start">{{ shortdateFormated(tripRequest.start) }}</span>
                </div>
            </div>
            <div class="trip-request__detail__row" v-if="tripRequest.duration">
                <div class="trip-request__detail__key">{{ translates[langUrl].duration[lang] }}:</div>
                <div class="trip-request__detail__value">
                    {{ tripRequest.duration }} {{ lastDigitToWord(tripRequest.duration) }}
                </div>
            </div>
        </div>
        <div class="trip-request__detail__rows" v-else>
            <div class="trip-request__detail__row">
                <div class="trip-request__detail__key">{{ translates[langUrl].trip[lang] }}:</div>
                <div class="trip-request__detail__value">
                    {{ tripRequest.trip.name }}
                </div>
            </div>
            <div class="trip-request__detail__row">
                <div class="trip-request__detail__key">{{ translates[langUrl].start[lang] }}:</div>
                <div class="trip-request__detail__value">
                    <span v-if="tripRequest.start">{{ tripRequest.start | moment('LL') }}</span>
                </div>
            </div>
            <div class="trip-request__detail__row" v-if="tripRequest.duration">
                <div class="trip-request__detail__key">{{ translates[langUrl].duration[lang] }}:</div>
                <div class="trip-request__detail__value">
                    {{ tripRequest.trip.programs.length }} {{ lastDigitToWord(tripRequest.trip.programs.length) }}
                </div>
            </div>
            <div class="trip-request__detail__row">
                <div class="trip-request__detail__key">{{ translates[langUrl].priceForPersone[lang] }}:</div>
                <div class="trip-request__detail__value">
                    <span v-html="priceFormated(tripRequest.trip.price, tripRequest.trip.currency)"></span>
                </div>
            </div>
            <div class="trip-request__detail__row">
                <div class="trip-request__detail__key">{{ translates[langUrl].numberOfParticipants[lang] }}:</div>
                <div class="trip-request__detail__value">
                    {{ tripRequest.bloggerPlaces }}
                </div>
            </div>
        </div>
        <div class="trip-request__detail__row" v-if="tripRequest.direction">
            <div class="trip-request__detail__key">{{ translates[langUrl].activities[lang] }}:</div>
            <div class="trip-request__detail__value">
                {{ activities(tripRequest) }}
            </div>
        </div>
        <div class="trip-request__detail__container">
            <div class="trip-request__detail__row">
                <div class="trip-request__detail__key">{{ translates[langUrl].workshops[lang] }}:</div>
                <div class="trip-request__detail__value">
                    {{ tripRequest.workshops }}
                </div>
            </div>
            <!-- <BaseButton
                class="button"
                @click="chooseTrip(tripRequest)">{{ translates[langUrl].button_chooseTrip[lang] }}</BaseButton> -->
            
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { priceFormated, lastDigitToWord, shortDateFormated } from '@/helpers';
    import { TRIP_TYPE, TRIP_REQUEST_STATUS } from '@/vars';
    import BaseButton from '../../components/common/BaseButton';

    export default {
        name: 'TripRequest',
        components: {
            BaseButton
        },
        props: {
            tripRequest: {
                type: Object,
                default: () => {}
            },
            showHeader: {
                type: Boolean,
                default: false
            },
            showButton: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            TRIP_TYPE, 
            TRIP_REQUEST_STATUS,
            priceFormated,
            shortDateFormated,
            lastDigitToWord,
            langUrl: '/operator/trip-requests'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('tripRequests', {
                tripRequests: state => state.entities
            }),
            ...mapState('quiz', ['dictionaries']),
            ...mapState('translates', {
                translates: state => state.entities
            })
        },
        methods: {
            formattedDirections(value) {
                return value && this.dictionaries.directions ? this.dictionaries.directions.find(item => (item.code.toString() === value.toString())).title : '';
            },
            formattedDirectionsAdditional(value) {
                return value && this.dictionaries.directionsAdditional ? this.dictionaries.directionsAdditional.find(item => (item.code.toString() === value.toString())).title : '';
            },
            formattedComfort(value) {
                return value && this.dictionaries.comforts ? this.dictionaries.comforts.find(item => (item.code.toString() === value.toString())).title : '';
            },
            activities(tripRequest) {
                if(this.dictionaries.activities) {
                    return tripRequest.activities.map(item => {
                        return this.dictionaries.activities.find(a => a.code.toString() === item.toString()).title;
                    }).join(', ');
                }
                return '';
            },
            async viewTrip() {
                const bloggerTrip = await store.dispatch('bloggerTrips/getByTripRequest', {
                    blogger: this.tripRequest.blogger._id, 
                    tripRequest: this.tripRequest._id
                });
                await this.$router.push({ name: 'operator-blogger-trip', params: { id: bloggerTrip._id }});
            },
            async editTrip() {
                await this.$router.push({ name: 'trip-edit', params: { id: this.tripRequest.trip._id }});
            }
        }
    }
</script>

<style lang="scss">
    .trip-request{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 16px 24px 24px;
        box-shadow: 0px 4px 20px rgba(44, 47, 56, 0.08);
        border-radius: 10px;
        margin-bottom: 32px;
        color: #3A4256;

        @media all and (max-width: 768px) {
            width: calc(100% - 40px);
            margin: 0 auto 32px;
        }
        
        &-answered {
            background: #dddddd;
        }
        &-canceled {
            background: #dddddd;
        }
        &__canceled {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border-radius: 12px !important;
            border: 1px solid #3A4256 !important;
            margin-bottom: 5px;
            height: 48px;
            align-self: center;
            background: none;
            font-size: 14px;
            line-height: 140%;
        }

        &__trip {
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.02em;
            margin-bottom: 14px;
        }
        &__user {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.02em;
            margin-bottom: 14px;
        }
        &__dates {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: -0.02em;
            padding: 16px 0;
            border-top: 1px solid #F6F7F9;
            border-bottom: 1px solid #F6F7F9;
            &__day {
                font-size: 14px;
                line-height: 140%;
                color: #9FA5AD;
            }
        }
        &__price {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: -0.02em;
            margin-top: 24px;
        }
        &__info {
            font-size: 12px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #9FA5AD;
            margin: 12px 0 24px;
        }

        &__detail {
            display: flex;
            flex-direction: column;
            // padding: 20px 20px 0 0;

            &__blogger {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 24px;
                @media all and (max-width: 768px) {
                    margin-bottom: 0;
                }
                &__title {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 150%;
                    margin-bottom: 8px;
                    color: #3A4256;
                    padding: 0 20px 0 0;
                    margin: auto 0;
                    @media all and (max-width: 768px) {
                        margin-bottom: 10px;
                    }
                }
                &__links {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    &__item {
                        text-decoration: underline;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 140%;
                        margin-left: 20px;
                    }
                }
                @media all and (max-width: 768px) {
                    padding: 20px 0;
                    width: 100%;
                    flex-direction: column;
                    border-left: none;
                    border-bottom: none;
                }
            }
            &__columns {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-left: -20px;
                @media all and (max-width: 768px) {
                    margin-left: 0;
                }
            }
            &.column {
                padding: 0 20px;
                margin: 10px 0;
                border-left: 1px #EDEDED solid;
                max-width: 500px;

                &:first-of-type {
                    border: none;
                }
                @media all and (max-width: 768px) {
                    padding: 0 0 20px;
                    width: 100%;
                    border-left: none;
                    border-bottom: 1px #EDEDED solid;
                    &:first-of-type {
                        margin-right: 0;
                        border-bottom: 1px #EDEDED solid;
                    }
                }
            }
            &__row {
                display: flex;
                flex-direction: row;
                margin-bottom: 12px;
                @media all and (max-width: 768px) {
                    flex-direction: column;
                }
            }
            
            &__key {
                display: flex;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                // margin-bottom: 8px;
                margin-right: 8px;
                // color: #A1A4B1;
                color: #273155;
                align-items: flex-end;
            }
            &__value {
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                .comment {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                    color: #A1A4B1;

                }
            }
            &__container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                @media all and (max-width: 768px) {
                    flex-direction: column;
                }
            }
        }
        button {
            width: 220px;
            // margin: 0 auto 0 0;
        }
    }
</style>
